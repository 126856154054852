import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

import HeaderDrawer from './HeaderDrawer'
import HeaderTabs from './HeaderTabs'
import HideOnScroll from './HideOnScroll'

import logo from '../assets/logo.jpg'
import { routes } from '../routes'

const useStyles = makeStyles(theme => ({
  logo: {
    height: "6rem",
    width: "6rem",
    [theme.breakpoints.down("md")]: {
      height: "5rem",
      width: "5rem"
    },
    [theme.breakpoints.down("xs")]: {
      height: "4rem",
      width: "4rem"
    }
  },
  logoContainer: {
    padding: 0
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "3em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2em"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.25em"
    }
  }
}))

const Header = ({ page, setPage }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const breakpointMatches = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <>
      <HideOnScroll>
        <AppBar className={classes.appbar} color="primary" position="sticky">
          <Toolbar disableGutters={true}>
            <Button
              className={classes.logoContainer}
              component={Link}
              to="/"
            >
              <img
                alt="startup maffia logo"
                className={classes.logo}
                src={logo}
              />
            </Button>
            {
              breakpointMatches
                ?
                <HeaderDrawer
                  drawerOpen={drawerOpen}
                  page={page}
                  routes={routes}
                  setDrawerOpen={setDrawerOpen}
                  setPage={setPage}
                />
                :
                <HeaderTabs
                  page={page}
                  routes={routes}
                  setPage={setPage}
                />
            }
          </Toolbar>
        </AppBar>
      </HideOnScroll>

    </>
  )
}

export default Header