export const routes = [
  {
    name: "Startup Maffia",
    link: "/",
  },
  {
    name: "Események",
    link: "https://www.facebook.com/startupmaffia/events",
  },
];
