import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

import OrganizerCard from "../components/OrganizerCard";

import bookIcon from "../assets/book.svg";
import groupImage from "../assets/group.jpg";
import lightBulbIcon from "../assets/light-bulb.svg";
import networkIcon from "../assets/network.svg";

import barnaIstvan from "../assets/barna_istvan.jpg";
import dornyeiBendeguz from "../assets/dornyei_bendeguz.jpg";
import gyarmatiJozsef from "../assets/gyarmati_jozsef.jpg";
import jatekosBalazs from "../assets/jatekos_balazs.jpg";
import takacsPeter from "../assets/takacs_peter.jpg";
import kookDavid from "../assets/kook_david.jpg";

const useStyles = makeStyles((theme) => ({
  callToActionButton: {
    fontSize: "1.5rem",
    marginLeft: "auto",
    marginRight: "auto",
  },
  heroContent: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  heroIcon: {
    height: "10rem",
    width: "10rem",
  },
  heroIconContainer: {
    alignItems: "center",
    display: "flex",
    height: "10rem",
    justifyContent: "center",
    padding: 5,
    width: "10rem",
  },
  heroImage: {
    width: "100%",
  },
  heroImageContainer: {
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      height: "100vh",
      overflow: "hidden",
    },
  },
  heroRow: {
    marginBottom: "5rem",
    paddingLeft: "10%",
    paddingRight: "10%",
    [theme.breakpoints.down("md")]: {
      marginBottom: "4rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "3rem",
    },
  },
  heroText: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  overlay: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.primary.light,
    zIndex: 2,
    opacity: 0.75,
    color: theme.palette.secondary.main,
  },
}));

const organizers = [
  {
    content:
      "A Startup Maffiát 2019-ben indítottam az egyik startup projektem lezárása után, melyből mind szakmailag, mind emberileg nagyon sokat fejlődtem. Azért hoztam létre ezt a fórumot, hogy a résztvevők saját vállalkozás ötleteikről tudjanak kötetlenül beszélgetni, emellett pedig egymástól is meghívott előadóinktól tanulhassanak. A Startup Maffiát két évig vezettem és szerveztem, 2021-ben adtam át a szervezet irányítását az új generációnak.",
    name: "Barna István",
    image: barnaIstvan,
    position: "Alapító",
  },
  {
    content:
      "Agilis és kitartó személyiség vagyok. Célom értéket teremteni a közösségeimben, és a társadalmi, környezeti hatás elérése. A Startup Maffia elnöki pozícióval, melyet egy évig töltök be, ezt a célt szeretném minél jobban szolgálni. Korábban több díjnyertes startup társalapítója és aktív tagja voltam, 15 évesen a háztartási ételpazarlás csökkentésével kezdtem el foglalkozni, illetve 17 évesen a Skeebdo nyelvtanuló applikáció piacra vitelét is nagymértékben segítettem. 2015-ben Templeton Fellow díjat kaptam, 2019-ben a nemzetközi Entrepreneur Leadership Academy győzetese lettem. Tavaly decemberben felvételt nyertem a Yale egyetemre, ami gyökeresen megváltoztatta az életemet. A Startup Maffiával indulása óta már több, mint 41 eseményt szerveztünk, és összesen 100.000-nél is több érdeklődőnek segítettünk a vállalkozásindítás valamely részében.",
    name: "Dörnyei Bendegúz",
    image: dornyeiBendeguz,
    position: "Elnök",
  },
  {
    content:
      "Entrópianövelő energia bomba, sokan így hivatkoznak rám a mindig vidám, izgő-mozgó és emberközpontú személyiségem miatt. A startup világba 2019-ben csöppentem bele, a 3D betonnyomtatásra épülő startup ötletemmel, amiről később a szexuális edukációra irányult a figyelmem. Az ökoszisztéma másik oldalán is kipróbáltam magam, egy holland innovációs ügynökségnél dolgoztam Startup scout-ként. Termék menedzsment, sales, üzletfejlesztési tapasztalattal rendelkezem, ami műszaki látásmóddal egészül ki járműmérnöki diplomám által. A Startup Maffia csapatát 2021 óta erősítem.",
    name: "Takács Péter",
    image: takacsPeter,
    position: "Szervező",
  },
  {
    content:
      "Egész karrierem során kutatás-fejlesztésben dolgoztam egyetemi és vállalati környezetben. Fizikából szereztem PhD fokozatot. Az utóbbi években azonban inkább az innovációs csapatok működése és az agilis fejlesztési módszertanok szerepe foglalkoztat. 3-4 éve innováció managementtel foglalkozom. A Bosch Csoport budapesti irodájában innovációs programokat építésében és vezetésében vettem részt. Most egy magyar health tech spin-off-nál vezetem az innovációért felelős csapatot. Szeretek önkéntes, társadalmi célokat támogató kezdeményezésekben részt venni. Mielőtt csatlakoztam a Startup Maffia szervezőihez, két évig egy vitakör vezetőségében tevékenykedtem és a viták moderálásáért feleltem.",
    name: "Játékos Balázs",
    image: jatekosBalazs,
    position: "Szervező",
  },
  {
    content:
      "Gyarmati József vagyok, aktív szervezője és büszke tagja a Startup Maffia Egyesületnek. Események szervezésével és közösségépítéssel igyekszem erősíteni a csapatot. A csatlakozásomat követően azonnal láttam, hogy mekkora potenciál van a Startup Maffiában és milyen nagyszerű közösség van itt. Jó ide tartozni!",
    name: "Gyarmati József",
    image: gyarmatiJozsef,
    position: "Szervező",
  },
  {
    content:
      "Több éve vagyok vállalkozó és mindig is az hajtott, hogyan lehetne bizonyos dolgokat jobbá, hatékonyabbá és fenntarthatóbbá tenni. Soha nem másolni akartam, hanem teremteni, fejleszteni, illetve ezt a tudást megosztani másokkal. ",
    name: "Koók Dávid",
    image: kookDavid,
    position: "Szervező",
  },
];

const LandingPage = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" style={{ width: "100vw" }}>
      <Grid item style={{ width: "100vw" }}>
        <Grid className={classes.heroImageContainer}>
          <div className={classes.overlay}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item>
                    <Typography
                      className={classes.heroText}
                      align="center"
                      variant="h3"
                    >
                      Szerezz új ismereteket, építs kapcsolatokat és hozd ki a
                      legtöbbet a startupodból!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} justify="center" style={{ marginTop: "1rem" }}>
                <Grid container justify="center">
                  <Grid>
                    <a
                      href="https://bit.ly/3JPHC7r"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        className={`${classes.callToActionButton} ${classes.heroText}`}
                        color="secondary"
                        variant="outlined"
                      >
                        Csatlakozz!
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <img
            className={classes.heroImage}
            src={groupImage}
            alt="group icon"
          />
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: "5rem", width: "100vw" }}>
        <Grid container direction="column">
          <Grid item className={classes.heroRow}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={10}>
                <Typography className={classes.heroContent} variant="h4">
                  Tanulj előadóinktól és a többi résztvevőtől!
                </Typography>
                <Typography className={classes.heroContent} variant="subtitle1">
                  A Startup Maffia előadásaira rendszeresen vállalkozókat és
                  szakembereket hívunk meg, akik tudásuk, tapasztalatuk átadása
                  mellett történetükkel inspirálnak minket. Megfordultak már
                  nálunk kezdő startupperek, nemzetközi szinten elismert cégek
                  és népszerű befektetők is, akiktől rengeteget tudtunk tanulni.
                  Az eseményeinket rendszeresen egy kötetlen beszélgetésen
                  követi, ahol te magad is tudsz az előadóktól kérdezni!
                </Typography>
              </Grid>
              <Grid item className={classes.heroIconContainer} xs={12} md={2}>
                <img
                  className={classes.heroIcon}
                  src={bookIcon}
                  alt="book icon"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.heroRow}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={10}>
                <Typography className={classes.heroContent} variant="h4">
                  Vitasd meg a résztvevőkkel az ötleteid és a vállalkozásodban
                  felmerült kihívásokat!
                </Typography>
                <Typography className={classes.heroContent} variant="subtitle1">
                  Van egy jó startup ötleted, amit szívesen megvitatnál
                  másokkal? Csapattársakat keresel? Kihívásokkal találtad szembe
                  magad a vállalkozásodban és segítségre van szükséged? A
                  Startup Maffia közössége rendkívül színes, vannak közöttünk
                  szoftverfejlesztők, jogászok, marketingesek, így számos
                  különböző látásmód alapján fogsz visszajelzést kapni.
                </Typography>
              </Grid>
              <Grid item className={classes.heroIconContainer} xs={12} md={2}>
                <img
                  className={classes.heroIcon}
                  src={lightBulbIcon}
                  alt="lightbulb icon"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.heroRow}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={10}>
                <Typography className={classes.heroContent} variant="h4">
                  Építs kapcsolatokat kezdő startupperekkel és elismert
                  vállalkozókkal!
                </Typography>
                <Typography className={classes.heroContent} variant="subtitle1">
                  A vállalkozói életben a jó kapcsolatok igazi kincset
                  jelentenek. Nem csak szakmai tanácsokat kaphatsz piackutatás,
                  szoftverfejlesztés és a jog területen, hanem megtalálhatod
                  elkövetkező csapattársaidat vagy jövendőbeli partnereidet is.
                </Typography>
              </Grid>
              <Grid item className={classes.heroIconContainer} xs={12} md={2}>
                <img
                  className={classes.heroIcon}
                  src={networkIcon}
                  alt="network icon"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className={classes.heroRow}
        style={{ marginTop: "5rem", textAlign: "center", width: "100vw" }}
      >
        <Typography variant="h3">Történetünk</Typography>
        <Typography variant="subtitle1">
          <p>
            A Startup Maffia ötlete 2019 nyarán született meg a budapesti
            Demolában az ottani alkalmi brainstormingok nyomán. Azzal a céllal
            indultunk el, hogy egy olyan fórum legyünk, ahol a résztvevők
            beszélgethetnek saját startup ötleteikről és a projektjeikben
            felmerült problémákról, emellett pedig egymástól és a meghívott
            szakemberektől tanulhatnak. Azt szerettük volna elérni, hogy az
            alkalmaink hozzásegítsék az érdeklődő fiatalokat és egyetemistákat,
            hogy saját vállalkozást indítsanak vagy könnyebben vegyék a meglévő
            startupjukban felmerülő kihívásokat. Eddig már 25 alkalmon vagyunk
            túl, voltak már nálunk fiatal vállalkozók, ismert befektetők és
            sikeres cégvezetők is. Különösen fontosnak tartjuk, hogy olyan
            légkört alakítsunk ki, amiben a résztvevők kötetlenül tudnak
            beszélgetni az előadókkal. A koronavírus járvánnyal járó
            korlátozások miatt átköltöztünk a digitális térbe, azóta csak két
            személyes alkalmunk volt, az összes többit online tartottuk meg. Ez
            hozzásegített minket ahhoz, hogy ne csak 10-20 fős eseményeket
            tartsunk, hanem érdeklődő emberek ezreit érjük el Facebook
            streamjeinkkel.
          </p>
        </Typography>
      </Grid>
      <Grid item style={{ width: "100vw" }}>
        <Grid container justify="center">
          <Typography variant="h3">Szervezők</Typography>
        </Grid>
        <Grid container className={classes.heroRow} spacing={2}>
          {organizers.map((o) => (
            <OrganizerCard
              content={o.content}
              image={o.image}
              name={o.name}
              position={o.position}
              xs={12}
              sm={6}
              md={3}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item style={{ width: "100vw" }}>
        <Grid container justify="center" style={{ width: "100vw" }}>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100vw !important",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "20px",
              boxSizing: "border-box",
            }}
          >
            <Grid
              item
              style={{ width: "100vw !important", lineBreak: "anywhere" }}
            >
              <Typography variant="h3">Kapcsolat</Typography>
            </Grid>
            <Grid
              item
              style={{ width: "100vw !important", lineBreak: "anywhere" }}
            >
              <Typography variant="h6">
                Email: dornyei.bendeguz@startupmaffia.hu
              </Typography>
            </Grid>
            <Grid
              item
              style={{ width: "100vw !important", lineBreak: "anywhere" }}
            >
              <Typography variant="h6">
                Facebook:{" "}
                <a
                  href="https://www.facebook.com/startupmaffia"
                  target="_blank"
                >
                  https://www.facebook.com/startupmaffia
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
