import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: `#000000`
    },
    secondary: {
      main: `#FFFFFF`
    }
  },
  typography: {
    fontFamily: "Montserrat",
    subtitle1: {
      color: "gray",
      fontSize: "1.25rem",
      fontWeight: 300
    },
    tab: {
      fontSize: "1rem",
      fontWeight: 700,
      textTransform: "none",
    }
  }
})

export default theme