import React from "react";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { ExternalUrl } from "./ExternalUrl";

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.secondary,
  },
  drawerIcon: {
    color: theme.palette.secondary,
    height: 50,
    width: 50,
  },
  drawerItem: {
    ...theme.typography.tab,
    color: "black",
    opacity: 0.7,
  },
  drawerIconContainer: {
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerItemSelected: {
    "& .MuiListItemText-root": {
      opacity: 1,
    },
  },
}));

const HeaderDrawer = ({ drawerOpen, page, routes, setDrawerOpen, setPage }) => {
  const classes = useStyles();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <SwipeableDrawer
        classes={{ paper: classes.drawer }}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <List disablePadding={true}>
          {routes.map((route) => (
            <ListItem
              key={`${route}${route.activeIndex}`}
              button={true}
              classes={{ selected: classes.drawerItemSelected }}
              component={route.link.startsWith("http") ? ExternalUrl : Link}
              divider={true}
              onClick={() => {
                setPage(route.activeIndex);
                setDrawerOpen(false);
              }}
              selected={page === route.activeIndex}
              to={route.link}
            >
              <ListItemText
                className={classes.drawerItem}
                disableTypography={true}
              >
                {route.name}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setDrawerOpen(!drawerOpen)}
      >
        <MenuIcon className={classes.drawerIcon} color="secondary" />
      </IconButton>
    </>
  );
};

export default HeaderDrawer;
