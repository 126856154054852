import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  organizerCard: {
    height: 500,
    [theme.breakpoints.down("md")]: {
      height: 275,
    },
    overflowY: "hidden",
    border: "1px solid lightgray",
  },
  organizerCardContainer: {
    height: "100%",
    width: "100%",
    marginBottom: "0.5rem",
    overflow: "hidden",
  },
  organizerContent: {
    fontSize: "1.2rem",
    height: "100%",
    overflowY: "auto",
    padding: "5px",
    paddingBottom: "0px",
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  organizerImage: {
    filter: "grayscale(100%)",
  },
  organizerName: {
    fontSize: "2rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  organizerPosition: {
    fontSize: "1.5rem",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  media: {
    height: 400,
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
  },
}));

const OrganizerCard = ({ content, image, name, position, xs, sm, md, lg }) => {
  const classes = useStyles();
  const [flipped, setFlipped] = useState(false);

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      <div
        className={classes.organizerCardContainer}
        onMouseEnter={() => setFlipped(true)}
        onMouseLeave={() => setFlipped(false)}
      >
        <ReactCardFlip isFlipped={flipped}>
          <Card className={classes.organizerCard}>
            <CardMedia
              className={classes.media}
              classes={{ root: classes.organizerImage }}
              image={image}
            />
            <CardContent>
              <Typography
                className={classes.organizerName}
                align="center"
                variant="h4"
              >
                {name}
              </Typography>
              <Typography
                className={classes.organizerPosition}
                align="center"
                variant="h5"
              >
                {position}
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.organizerCard}>
            <CardContent className={classes.organizerContent}>
              {content}
            </CardContent>
          </Card>
        </ReactCardFlip>
      </div>
    </Grid>
  );
};

export default OrganizerCard;
