import React, { useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Footer from './components/Footer'
import Header from './components/Header'
import LandingPage from './views/LandingPage'

import theme from './tools/Theme'

const App = () => {
  const [page, setPage] = useState(0)
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header page={page} setPage={setPage} />
        <Switch>
          <Route
            component={LandingPage}
            exact={true}
            path="/"
          />
          <Route
            component={() => <div>rolunk</div>}
            exact={true}
            path="/rolunk"
          />
          <Route
            component={() => <div>kuldetes</div>}
            exact={true}
            path="/kuldetes"
          />
          <Route
            component={() => <div>esemenyek</div>}
            exact={true}
            path="/esemenyek"
          />
        </Switch>
        <Footer
        //selectedIndex={selectedIndex}
        //setSelectedIndex={setSelectedIndex}
        //setValue={setValue}
        //value={value}
        />
      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
