import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ExternalUrl } from "./ExternalUrl";

import { routes } from "../routes";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    position: "relative",
    width: "100%",
  },
  footerItem: {
    color: theme.palette.secondary.main,
    fontFamily: "Arial",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textDecoration: "none",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid container justify="center" spacing={2}>
        {routes.map((r) => (
          <Grid
            item
            key={r.link}
            className={classes.footerItem}
            component={r.link.startsWith("http") ? ExternalUrl : Link}
            to={r.link}
          >
            {r.name}
          </Grid>
        ))}
      </Grid>
    </footer>
  );
};

export default Footer;
