import React from "react";

export const ExternalUrl = (props) => {
  return (
    <a
      className={props.className}
      href={props.to}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};
