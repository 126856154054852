import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { ExternalUrl } from "./ExternalUrl";

const useStyles = makeStyles((theme) => ({
  tab: {
    ...theme.typography.tab,
    marginLeft: "25px",
    minWidth: 10,
  },
  tabContainer: {
    marginLeft: "auto",
  },
}));

const HeaderTabs = ({ page, routes, setPage }) => {
  const classes = useStyles();

  const handleTabChange = (e, tab) => {
    setPage(tab);
  };

  return (
    <Tabs
      className={classes.tabContainer}
      indicatorColor="primary"
      onChange={handleTabChange}
      value={page}
    >
      {routes.map((route, index) => (
        <Tab
          key={`${route}${index}`}
          aria-owns={route["aria-owns"]}
          aria-haspopup={route["aria-haspopup"]}
          className={classes.tab}
          component={route.link.startsWith("http") ? ExternalUrl : Link}
          label={route.name}
          to={route.link}
        />
      ))}
    </Tabs>
  );
};

export default HeaderTabs;
